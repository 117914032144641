.mapContainer {
  height: 400px;
}

.WeatherWidgetContainer {
  margin: 2rem 0;
}

.WeatherWidget {
  overflow: scroll;
}

.Headline {
  position: absolute;
  bottom: 5rem;
  left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  user-select: none;

  h1,
  span {
    text-shadow: 0 0 10px var(--color-shadow-transparent);
    margin: 0;
  }
  h1 {
    font-size: 4rem;
    line-height: 3.8rem;
    text-align: end;
  }
  span {
    font-size: 1.2rem;
    font-weight: normal;
    position: relative;
    top: -2rem;
  }
}

.hero {
  height: calc(100vh - 3rem);

  .WeatherWidgetHero {
    position: absolute;
    top: 7rem;
    right: 1.2rem;
    max-width: calc(100vw - 2.4rem);
  }
}

.main {
  background-color: var(--color-background-transparent);
  min-height: calc(100vh - 57px);
  padding-bottom: 2rem;
  position: relative;

  @keyframes toggleAnimation {
    0%, 100% {
      opacity: 1;
    }
  
    50% {
      opacity: 0;
    }
  }

  &::before {
    content: "^";
    position: absolute;
    color: var(--color-background-transparent);
    font-size: 50px;
    font-weight: 700;
    top: -50px;
    left: calc(50% - 16px);
    animation: toggleAnimation 2s infinite;
  }
}

.contentBox {
  padding-bottom: 1rem;

  h1 {
    margin-left: 1.2rem;
  }

  h2 {
    font-size: 2rem;
    margin: 2rem 0;

    overflow: hidden;
    text-overflow: ellipsis;
  }

  > h2,
  > h3,
  > p,
  > div {
    padding: 0 1.2rem;

    &.mapContainer {
      padding: 0;
      margin: 2rem 0;
    }
  }

  > h3 {
    font-weight: 300;
    margin: 2rem 0;
  }

  .chartBox {
    min-height: 300px;
  }

  .readMore {
    text-align: right;
  }
}

p > .customMarker {
  height: 20px;
  margin-bottom: -4px;
}

.static {
  .content {
    height: 100vh;
    width: 100vw;
    position: fixed;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    a {
      text-decoration: none;
    }

    h1 {
      margin: 1rem;
    }
  }
}

ul.noStyleType {
  list-style-type: none;
  margin: 0;
  padding: 0 1.2rem;

  li {
    display: flex;
    align-items: center;
    padding: .5rem;

    &:hover {
      cursor: pointer;
    }

    svg {
      margin-right: .8rem;
    }
  }
}

.MuiCircularProgress-root {
  color: #888;
}

.MuiImageList-root {
  margin: 3rem 0;
}

.customButton {
  border-color: var(--font-color);
  color: var(--font-color);

  &:hover {
    border-color: var(--font-color);
    background-color: rgba(255,255,255,.2);
  }
}

.route {
  > ul.details {
    list-style-type: none;
    padding-left: 1.2rem;
  }
}

.fileNotFound {
  .main {
    background-color: transparent;
  }
}

@media (min-width: 600px) {
  ul.noStyleType {
    padding: 0;
  }

  .WeatherWidgetHero {
    right: 1.6rem;
  }

  .Headline {
    bottom: 10rem;
    left: 5rem;
    margin-right: 5rem;
  
    h1 {
      font-size: 6rem;
      line-height: 5rem;
    }
    span {
      font-size: 2rem;
      top: -3rem;
    }
    .scrollTopRef {
      height: 6rem;
    }
  }

  .contentBox {
    h2 {
      font-size: 2.6rem;
    }
    h2,
    h3,
    > p,
    > div {
      padding: 0;
    }
    h3 {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
}