.PageLoader {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 10000;
    background-color: var(--color-background);
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;

    &.fadeOut {
        opacity: 1;
        transition: opacity 1s ease-in-out;

        &.loaded {
            opacity: 0;
        }
    }
}