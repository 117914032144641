.Gallery {
    padding: 5px;

    img {
        border-radius: 5px;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, .4);
        position: relative;
        z-index: 0;
        transition: opacity 1s ease-in-out;
        opacity: 0;

        &.fadeIn {
            opacity: 1;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &.animate {
        img {
            transition: transform .15s ease-in-out, opacity .7s ease-in-out;

            &:hover {
                transform: scale(1.05);
                border-radius: 7px;
                z-index: 1;
            }
        }
    }

    .CircularProgress {
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
    }
}