// For new sites add the page name (also set as class in the DOM root element) and the name
// of the background image (without file extension - needs to be provided as jpeg and avif)
// as key-value-pair to the $sites map
// These images /public/img/bg/

$backgroundFolder: '../public/img/bg/';

$sites: (
    'fileNotFound': '404',
    'home_1': 'Talaia_de_Alcudia',
    'home_2': 'Platja_de_Muro',
    'home_3': 'Cala_Mondrago_2',
    'home_4': 'Sa_Rapita_Nacht',
    'home_5': 'Cala_Anguila',
    'home_6': 'Cala_Falco',
    'home_7': 'Cala_Torta',
    'home_8': 'Cova_des_Pont',
    'home_9': 'Fumat',
    'home_10': 'Mallorca_Caravan',
    'home_11': 'Parc_natural_de_Mondrago_2',
    'home_12': 'Platja_de_Muro_Zugang',
    'home_13': 'Platja_des_Caragol',
    'home_14': 'Platja_des_Coll_Baix',
    'home_15': 'Platja_des_Carbo',
    'home_16': 'Sa_Foradada',
    'home_17': 'SAlmunia',
    'home_18': 'Valldemossa',
    'home_19': 'Sunset',
    'attraktionen': 'Palma_de_Mallorca',
    'klima': 'Cala_Mondrago_square',
    'strand': 'Arenal_de_Sa_Rapita',
    'platja_des_caragol': 'Platja_des_Caragol',
    'cala_salmunia': 'SAlmunia',
    'calo_des_moro': 'Calo_des_Moro',
    'platja_de_samarador': 'SAmarador',
    'cala_mondrago': 'Cala_Mondrago',
    'platja_de_muro': 'Platja_de_Muro_Zugang_2',
    'cala_anguila': 'Cala_Anguila',
    'aktivitaeten': 'Mallorca_Caravan',
    'radfahren': 'Radfahren',
    'angeln': 'Puerto_de_Sa_Rapita',
    'wandern': 'Wandern',
    'gr221': 'GR221',
    'sa_foradada': 'Sa_Foradada',
    'coll_baix': 'Platja_des_Coll_Baix',
    'cap_de_ses_salines': 'Far_des_Cap_de_ses_Salines',
    'puig_de_maria': 'Pollenca_vom_Puig_de_maria',
    'penya_des_migdia': 'Ses_Pasteres',
    'torrent_de_boquer': 'Torrent_de_Boquer',
    'cova_des_pont': 'Cova_des_Pont',
    'cala_torta': 'Cala_Torta',
    'torrent_de_pareis': 'Torrent_de_Pareis',
    'platja_des_carbo': 'Platja_des_Carbo',
    'fumat': 'Fumat',
    'schutzgebiete': 'Cabrera',
    'alcudia': 'Portal_del_Moll_Alcudia',
    'castell_de_bellver': 'Castell_de_Bellver',
    'palma_de_mallorca': 'Ajuntament_de_Palma_de_Mallorca',
    'la_seu': 'Kathedrale_La_Seu_in_Palma',
    'soller': 'Iglesia_de_Sant_Bartomeu_in_Soller',
    'valldemossa': 'Valldemossa',
    'inca': 'Esglesia_de_Santa_Maria_de_Inca',
    'sa_pobla': 'Sa_Pobla',
    'santuari_de_lluc': 'Santuari_de_Lluc',
    'naturparks': 'Parc_natural_de_Mondrago',
    'hoehlen': 'Coves_de_Campanet',
    'mallorca_mit_kindern': 'Kinder_am_Strand',
    'datenschutz': 'Mallorca_Landschaft_Meer',
    'impressum': 'Ses_Salines_Granatapfel',
    'financial_district': 'Financial_District'
    // add new entries here
);
// Handles bg image dependend if class avif is set in DOM
@mixin setBackgroundImage($image) {
    &.avif {
        .background {
            background-image: url($backgroundFolder+$image+'.avif')
        }
    }

    &.jpeg {
        .background {
            background-image: url($backgroundFolder+$image+'.jpeg')
        }
    }
}
// Creates css classes for all $site in $sites and passes $image to setBackgroundImage mixin
@each $site, $image in $sites {
    .#{$site} {
        @include setBackgroundImage($image);
    }
}

.background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // transition: background-image .5s linear;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: -1;
}