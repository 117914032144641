.AppBar {
    box-shadow: none;
    background-color: transparent;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.4, 1) 0ms;

    &.visible {
        background-color: var(--color-light-transparent);
    }

    .MuiSvgIcon-root {
        color: var(--font-color);
    }
}

.ToolBar {
    padding: 0;
    align-items: stretch;
    min-height: 64px; // Due to Mui settings for .MuiToolbar-root
}

.Header {
    color: var(--font-color);
    display: flex;
    flex-grow: 0;
    background-color: var(--color-background-transparent);
    align-items: center;
    padding: 0 1.4rem;
    font-weight: bold;
    font-size: 2rem;
    user-select: none;
}

.MenuHeader {
    background-color: var(--color-shadow-transparent);
}

.AppBarButton {
    align-self: center;
    border-bottom: 2px solid transparent;
    font-weight: bold;
    font-size: 1rem;
    padding: 0 1rem;
    text-shadow: var(--color-background-transparent) 0 0 1px;
}

.Drawer {
    .MuiDrawer-paper {
        box-sizing: border-box;
        width: 240px;
        background-color: var(--color-background-transparent);
        color: var(--font-color);

        .MuiListItemText-primary {
          font-weight: bold;
        }
    }

    .ListItemButton {
        padding-left: 0;
    }

    .ListItemIcon {
        min-width: 40px;
        width: 40px;
        margin: 0 16px;
        display: flex;
        justify-content: center;
    }

    svg {
        color: var(--font-color);
    }

    a {
        flex: auto;
    }
}

.Switch {
    width: 100%;
    padding-left: 25px;
    margin-top: 40px;
    user-select: none;
}

ul.Menu,
ul.SubMenu {
    display: flex;
    color: var(--font-color);
    border-radius: 0;
    padding: 0;

    li {
        line-height: 3.3rem;
        height: 4rem;
        padding: 0;

        &:hover,
        &.active {
            background-color: var(--color-shadow-transparent);
        }
    }
}

ul.SubMenu {
    position: fixed;
    top: 4rem;
    right: 0;
    background-color: var(--color-shadow-transparent);
}