.Lightbox {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
        outline: none;

        &.imgContainer {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.controls {
            pointer-events: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: auto;

            > button {
                pointer-events: all;
                margin: 0 25px;
                border-radius: 50px;
                padding: 12px;
                min-width: 0;

                &.hidden {
                    visibility: hidden;
                }

                .MuiButton-startIcon {
                    margin: auto;
                }
            }
        }
    }

    img,
    picture {
        max-height: 100vh;
        // aspect-ratio: auto;

        &:hover {
            cursor: pointer;
        }
    }

    picture {
        outline: none;
        position: relative;

        .title {
            color: var(--font-color);
            background-color: var(--color-light-transparent);
            width: 100%;
            position: absolute;
            bottom: 0;
            max-width: 100vw;
            left: calc(50% - 50vw);
            padding: 1rem;
            text-align: end;
        }
    }

    img {
        transition: opacity .5s ease-in-out;
        opacity: 1;

        &.hide {
            opacity: 0;
        }
    }
}

@media (min-width: 600px) {
    .Lightbox {
        img,
        picture {
            max-width: 100vw;

            .title {
                left: 0;
            }
        }
    }
}