.Footer {
    background-color: var(--color-background-transparent);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 1rem;
    border-top: 1px solid var(--color-background);
    flex-direction: column;

    a {
        text-decoration: none;
        height: 3rem;
        min-width: 3rem;

        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    div {
        display: flex;
        flex-direction: row;
    }

    .socialMediaLinks {
        display: flex;
        margin-right: .6rem;
    }

    .copyright {
        white-space: nowrap;
    }
}

.static {
    .Footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

@media (min-width: 600px) {
    .Footer {
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
        
        a {
            height: 1.5rem;
            min-width: 1.5rem;
        }

        div:first-child {
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }
}