.Rating {
    display: flex;
    justify-content: flex-end;

    svg {
        margin: .2rem;
        opacity: .2;
    }

    .active svg {
        opacity: 1;
    }
}