$teaserWidth: 250px;
$scaleFactor: 1.05;

.Teasers {
    display: flex;
    align-items: center;
    height: 230px;
    margin: 0 .5rem;
    overflow-x: scroll;
    white-space: nowrap;

    .Teaser {
        margin: 0 .5rem;
        display: inline-block;
        width: $teaserWidth;

        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &.animate {
            transition: all .15s ease-in-out;

            &:hover {
                transform: scale($scaleFactor);
            }
        }

        img {
            transition: opacity .7s ease-in-out;
            opacity: 0;

            &.fadeIn {
                opacity: 1;
            }

            // &:hover {
            //     transform: scale(1.5);
            //     transition: transform 5s;
            // }
        }
    }

    .MuiCard-root {
        background-color: rgba(255, 255, 255, .1);
        //box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);

        .MuiCardContent-root {
            padding: .4rem .8rem;
            background-color: var(--color-background-transparent);

            .MuiTypography-root {
                color: var(--font-color);
                margin-bottom: .2rem;
            }

            .MuiTypography-h5 {
                font-size: 1.2rem;
                // font-weight: 400; // default
                font-weight: 300;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}