@use './ThumbnailMarker.scss';

#devTools {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 171px;
    padding: 90px 10px 10px;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    color: white;
    background: rgba(255,255,255,.3);
    //pointer-events: none;
    display: flex;
    flex-direction: column;
}
#info {
    background-color: var(--color-background-transparent);
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 8px;
}

#controls,
#routing {
    display: flex;
    flex-direction: column;
    gap: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    button {
        justify-content: start;
    }
}

#routing {
    padding-top: 20px;

    label {
        //font-size: .8rem;
    }
    input {
        background-color: rgba(255,255,255,.6);
        border-radius: 4px;
        //font-size: .5rem;
    }
}

.MuiDialog-paper {
    background-color: rgba(0,0,0,.4);
    padding: 15px;
    color: var(--font-color);
    font-size: .8rem;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .icon {
        &:hover {
            cursor: pointer;
        }
    }

    .closeDialog {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .dialog {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        h2 {
            margin-top: 0;
        }
    
        .headline {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .response {
            overflow-y: scroll;
        }
    }
}