@use './custom.mapboxgl.scss';
@use './backgrounds.scss';
@use './MapTools.scss';
// Components styles imports
@use './components/PageLoader.scss';
@use './components/DrawerAppbar.scss';
@use './components/Parallax.scss';
@use './components/Teasers.scss';
@use './components/Gallery.scss';
@use './components/Lightbox.scss';
@use './components/Rating.scss';
@use './components/Map.scss';
@use './components/Footer.scss';
@use './components/Paragraph.scss';

@mixin colorscheme-light {
  // color-scheme light
  --font-color: #111;
  --font-color-transparent: rgba(0, 0, 0, .4);
  --color-background: #fff;
  --color-background-transparent: rgba(255, 255, 255, 0.9);
  --color-light-transparent: rgba(255, 255, 255, 0.7);
  --color-shadow-transparent: rgba(255, 255, 255, 0.4);
}

@mixin colorscheme-dark {
  // color-scheme dark
  --font-color: #fff;
  --font-color-transparent: rgba(255, 255, 255, .4);
  --color-background: #222;
  --color-background-transparent: rgba(34, 34, 34, 0.9);
  --color-light-transparent: rgba(34, 34, 34, 0.7);
  --color-shadow-transparent: rgba(34, 34, 34, 0.4);
}

.colors {
  @include colorscheme-dark;

  &.light {
    @include colorscheme-light;
  }
}

@media (prefers-color-scheme: light) {
  .colors.auto {
    @include colorscheme-light;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, sans-serif;
  color: var(--font-color);
}

a {
  color: inherit;
}

p {
  text-align: justify
}

* {
  box-sizing: border-box;
}

.link {
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
}

.teaserHeader {
  margin: 0;
  padding: 1rem 1rem 0;
}