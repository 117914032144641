// Map
.mapboxgl-popup {
  color: var(--font-color);
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, sans-serif;
  user-select: none;

  .mapboxgl-popup-content {
    padding: 5px 8px;

    &,
    .mapboxgl-popup-tip {
      background-color: rgba(0, 0, 0, .6);
    }
  }

  &.mapboxgl-popup-anchor-bottom,
  &.mapboxgl-popup-anchor-bottom-left,
  &.mapboxgl-popup-anchor-bottom-right {
    .mapboxgl-popup-tip {
      border-top-color: rgba(0, 0, 0, .6);
    }
  }

  &.mapboxgl-popup-anchor-top,
  &.mapboxgl-popup-anchor-top-left,
  &.mapboxgl-popup-anchor-top-right {
    .mapboxgl-popup-tip {
      border-bottom-color: rgba(0, 0, 0, .6);
    }
  }

  &.mapboxgl-popup-anchor-left {
    .mapboxgl-popup-tip {
      border-right-color: rgba(0, 0, 0, .6);
    }
  }

  &.mapboxgl-popup-anchor-right {
    .mapboxgl-popup-tip {
      border-left-color: rgba(0, 0, 0, .6);
    }
  }
}