.Paragraph {
    clear: both;
    margin: 30px 0;
    padding: 0;

    img {
        border-radius: 0;
        margin: 0 0 1rem -1.2rem;
        width: calc(100% + 2.4rem);
    }
}

@media (min-width: 600px) {
    .Paragraph {
        padding: 0 1.2rem;
        
        img {
            border-radius: 8px;
            margin: 0 20px 10px 0;

            width: 250px;
            height: 187px;
            float: left;
            transition: transform .15s ease-in-out, opacity 1s ease-in-out;
            // mask-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0));

            &:hover {
                transform: scale(1.05);
            }

            &.right {
                float: right;
            }
        }

        &.right {
            img {
                float: right;
                margin: 0 0 10px 20px;
            }
        }
    }
}