$bgColor: rgba(34, 34, 34, .9);

.markerThumbnail {
    position: relative;
    border-radius: 5px;
    border: 2px solid $bgColor;
    width: 54px;
    height: 54px;

    &::before {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        left: calc(50% - 5px);
        bottom: -7px;
        background-color: $bgColor;
        transform: rotate(45deg);
        z-index: -1;
    }

    img {
        border-radius: 4px;
    }
}