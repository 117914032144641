.Parallax {
    display: flex;
    align-items: center;
    aspect-ratio: 1 / 1;
    opacity: 1;
    transition: opacity .7s ease-in-out;

    &.animate {
        opacity: 0;
      }

    h2 {
        display: flex;
        color: var(--font-color-transparent);
        position: relative;
        font-size: 20rem;
        font-weight: bold;
        margin-left: 4rem;
        white-space: nowrap;
        // Custom position
        margin-top: 550px;

        &.list {
            font-size: 10rem;
            justify-content: center;
            margin: auto;
            margin-top: 400px;
        }
    }
}

@media (min-width: 600px) {
    .Parallax {
        aspect-ratio: 2 / 1;
    }
}